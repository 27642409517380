<template>
  <div v-if="user">
    <p class="text-center text-sm text-light-text dark:text-dark-light-text">
      Balance
    </p>
    <p class="text-center text-2xl font-bold">
      ₦{{ formatAmount(user.account.balance || 0) }}
    </p>
  </div>
</template>

<script setup lang="ts">
const { user } = storeToRefs(useUserStore())
</script>
